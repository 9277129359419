body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.Spinner {
  width: 120px;
  margin: 24px auto;
}
.Login-Container {
  text-align: center;
}

.MuiTypography-gutterBottom.MuiTypography-gutterBottom {
  margin-bottom: 12px;
}

.MuiContainer-root {
  background: white;
  padding: 24px;
  border-radius: 4px;
  color: black;
  margin: 24px auto;
}

.MuiDivider-root.MuiDivider-root {
  border-width: 1px;
  width: 90%;
  border-color: rgb(0 0 0 / 55%);
  margin-bottom: 24px;
}

.MuiFormLabel-root,
.MuiFormGroup-root {
  margin-bottom: 16px;
}

.score {
  margin-bottom: 16px;
}

.MuiFormControlLabel-root {
  margin: 8px;
}

.MuiFormControlLabel-root:hover {
  background: #eaeaea;
  border-radius: 4px;
}

.MuiListItem-root {
  border-bottom: 1px solid #ccc;
}

.MuiFormControl-root {
  width: 100%;
}

.listWrapper {
  width: "100%";
  /* backgroundColor: theme.palette.background.paper,*/
}

.inline {
  display: "inline";
}

.Footer {
  font-size: 12px;
}
.Footer > a {
  color: white;
}
